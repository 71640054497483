$ = require('jquery')

scrollMonitor = () ->
  header = $(".global-header")
  heroSection = $(".homepage-signup__sitter")

  if heroSection.length > 0
    scrollPoint = heroSection.offset().top + heroSection.height()

    $(window).on 'scroll', () ->
      stop = Math.round($(window).scrollTop())
      if stop > scrollPoint
        header.addClass('collapse')
      else
        header.removeClass('collapse')

module.exports = scrollMonitor
