scrollMonitor = require("./header_scroll")
headerBanner = require("./header_banner")
closeHeaderSetup = require("./close_header")
navigationScroll = require("./navigation_scroll")
lazyLoad = require("./lazyload")

setup = () ->
  scrollMonitor()
  headerBanner()
  closeHeaderSetup()
  navigationScroll()

module.exports = setup
