debounce = (func, wait, immediate) ->
  timeout = undefined
  ->
    context = this
    args = arguments

    later = ->
      timeout = null
      if !immediate
        func.apply context, args
      return

    callNow = immediate and !timeout
    clearTimeout timeout
    timeout = setTimeout(later, wait)
    if callNow
      func.apply context, args
    return

navigationScroll = debounce((->
  header = document.querySelector('.header--desktop')
  window.addEventListener 'scroll', (e) ->
    if window.scrollY > 0
      header.classList.add 'header--scrolled'
    else
      header.classList.remove 'header--scrolled'
    return
  return
), 250)

module.exports = navigationScroll

