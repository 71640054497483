$ = require('jquery')

closeHeaderSetup = () ->
  menu = $('.nav-dropdown__wrapper')
  menu_checkbox = $('#dropdown__checkbox');
  checkbox = $('#header__checkbox')

  $('html').click (event) ->
    if menu.has(event.target).length == 0
      menu_checkbox.prop('checked', false)

  checkbox.click ()->
    if checkbox.prop('checked') == true
      $('body').addClass 'js-navigation-open'
      document.ontouchmove = (event) ->
    else
      $('body').removeClass 'js-navigation-open'
      document.ontouchmove = null;

module.exports = closeHeaderSetup
