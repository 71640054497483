$ = require('jquery')

headerBanner = () ->
  close = $(".global-banner__close-link")
  body = $("body")

  close.on 'click', (e) ->
    e.preventDefault()
    body.removeClass("show-banner")
    $.cookie("closebanner", true, {expires: 30, path: "/"})

module.exports = headerBanner
